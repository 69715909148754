import React from 'react'
import {FaArrowAltCircleRight, FaArrowAltCircleLeft} from "react-icons/fa"
import {CgCloseO} from "react-icons/cg"
import "./modal.css"

const Modal = ({clickedImg, handleRotationRight, setClickedImg, handleRotationLeft}) => {
  const handleClick = (e) => {
      if(e.target.classList.contains('dismiss') || e.target.classList.contains('close-x')) {
         setClickedImg(null)
      }
      // setClickedImg(null)
  }

  document.addEventListener('keydown', function(event){
	if(event.key === "Escape"){
		setClickedImg(null)
	}
});

   return (
    <>
      {/* <div className="overlay dismiss"  */}
      <div className="overlay dismiss" onClick={handleClick}
      >
         <img src={clickedImg} alt={clickedImg} className="overlay-image animation-img" />
         <span className='dismiss close-x' onClick={handleClick}>&#10005;</span>
         {/* <CgCloseO className='dismiss close-x' onClick={handleClick}/> */}
         <div onClick={handleRotationRight} className="overlay-arrows_left"><FaArrowAltCircleLeft /></div>
         <div onClick={handleRotationLeft} className="overlay-arrows_right"><FaArrowAltCircleRight /></div>
      </div>
    </>
  )
}

export default Modal
