import React from 'react'
import BackgroungImage from "../../assets/4873152.jpg"
import "./contact.css"
import Open from "../../assets/artem-beliaikin-Np7V88Hd8Es-unsplash.jpg"

const Contact = () => {
  return (
    <div className='container-contact' style={{ backgroundImage: `url(${BackgroungImage})` }}>
      <section className='open-shop'>
         <h3>Nyitvatartás:</h3> 
         <p>Hétfő: 12:00 – 18:00</p> 
         <p>Kedd-Péntek: 10:00 – 18:00</p> 
         <p>Szombat: Előzetes időpont egyeztetéssel!</p>
      </section>

      <section className='open-shop-img'><img src={Open} alt="" /></section>

      <section className='contact-img'>
      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2758.3348046931583!2d20.1486753766409!3d46.26345027975737!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4744880ed3012871%3A0x7309f51484b12056!2sTiffany%20Szalon!5e0!3m2!1shu!2shu!4v1709747909109!5m2!1shu!2shu" title="GeeksforGeeks" className='iframe' loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
      </section>
      
      <section className='contact'>
        
        <h3>Próbára bejelentkezés:</h3>
        <p>
          06-62/321-811; 06-30/649-0130
        </p>
        <h3 className='address-h3'>Cím:</h3><span className='contact-address'>Szeged, Csongrádi sgt. 29</span>
      </section>
    </div>
  )
}

export default Contact

