import React from 'react'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { Link } from 'react-router-dom'
import { Redirect } from 'react-router-dom'
import "./adminMenu.css"
import CheckroomIcon from '@mui/icons-material/Checkroom';
import DoneIcon from '@mui/icons-material/Done';
import HomeIcon from '@mui/icons-material/Home';
import InfoIcon from '@mui/icons-material/Info';
import LogoutIcon from '@mui/icons-material/Logout';
import GradingIcon from '@mui/icons-material/Grading';

const AdminMenu = () => {
   
   const history = useHistory()

   // ------------------ LOGOUT -----------------------------------
   const logout = () => {
      sessionStorage.removeItem("userData");
      history.push('/')
    }

    // -------------- CHECK USER'S LOGIN ---------------------------
       if(!sessionStorage.getItem("userData")){
      return <Redirect to= "/" />
   }

  return (
    <div>
      <h1>Admin Page</h1>
      <nav className='admin-nav-menu'>
        <ul className='admin-menu-ul'>
          <Link title="admin-clothes" to='/admin'><li className='admin-menu-li'><div><CheckroomIcon/></div><div>Ruhák</div></li></Link>
          <Link title="admin-clothes" to='/admin/sorting'><li className='admin-menu-li'><div><GradingIcon/></div><div>Sorrend</div></li></Link>
          <Link title="admin-adminchosenus" to='/admin/adminChosenUs'><li className='admin-menu-li'><div><DoneIcon/></div><div>Akik minket</div></li></Link>
          <Link title="admin-clothes" to='/admin/adminLanding'><li className='admin-menu-li'><div><HomeIcon/></div><div>Kezdőlap</div></li></Link>
          <Link title="admin-clothes" to='/admin/adminAbout'><li className='admin-menu-li'><div><InfoIcon/></div><div>Rólunk</div></li></Link>
          <li onClick={logout} className='admin-menu-li'><div><LogoutIcon/></div><div>Kilépés</div></li>
        </ul>
      </nav>
    </div>
  )
}

export default AdminMenu
