import Clothes from './components/admin/Clothes';
import AdminChosenUs from './components/admin/AdminChosenUs';
import AdminLanding from './components/admin/AdminLanding';
import { Helmet } from 'react-helmet';
import DisplayData from './components/displaydata/DisplayData';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import './App.css';
import Menu from './components/menu/Menu';
import Main from './components/main/Main';
import About from './components/about/About';
import Contact from './components/contact/Contact';
import ChosenUs from './components/chosen-us/ChosenUs';
import Footer from './components/footer/Footer';
import Login from './components/login/Login';
import NotFound from './components/notfound/NotFound';
import Sorting from './components/admin/Sorting';


function App() {

  return (
      <div className="App">
        <Router >
        <Helmet>
            <title>Tiffany - Esküvői és menyasszonyi ruhaszalon</title> 
            <meta name="description" content="Gyere el hozzánk, mert akár kölcsönözni, akár vásárolni szeretnéd ruhádat nálunk megtalálod! Varrást, készítést is vállalunk!" />
            <meta name="keywords" content="TIFFANY SZALON, ESKÜVŐI RUHÁK SZEGED, MENYASSZONYI RUHÁK, KÉSZÜLŐDŐS KÖNTÖSÖK, ÁTÖLTÖZŐS KÖNTÖSÖK, MENYASSZONYI KÖNTÖSÖK, SZATÉN ESKÖVŐI KÖNTÖSÖK, KÉSZÜLŐDŐS KÖNTÖSÖK, MENYECSKE RUHÁK, KISLÁNY RUHÁK, SZALAGAVATÓS RUHÁK,KOSZORÚSLÁNY RUHA, KISLÁNY RUHA, ELSŐÁLDOZÓ RUHA, MENYECSKE RUHA, VARRÁS, RUHAKÉSZÍTÉS, EGYEDI TERVEZÉS, SZABÁS, ÁTALAKÍTÁS, BÁLI RUHA, BÁLI TÁNC RUHA, KISMAMA RUHA, Nagy méretű esküvői ruha, Nagy méretű menyasszonyi ruha, Plus Size esküvői ruha, Molett esküvői ruha, 6XL-ig esküvői ruha, esküvői ruha 68-as méretig, Extra méretű esküvői ruha, Telt méretben vagy-és Telt méretű esküvői ruha" />
            <meta property="og:title" content="Tiffany - Esküvői és menyasszonyi ruhaszalon" />
            <meta property="og:type" content="product" />
            <meta property="og:url" content="https://tiffanyszalonszeged.hu/" />
            <meta property="og:description" content="Gyere el hozzánk, mert akár kölcsönözni, akár vásárolni szeretnéd ruhádat nálunk megtalálod! Varrást, készítést is vállalunk!" /> 
            <meta property="og:title" content="Tiffany - Esküvői és menyasszonyi ruhaszalon" />
            <meta property="og:type" content="product" />
            <meta property="og:url" content="https://tiffanyszalonszeged.hu/" />
            <meta property="og:description" content="Gyere el hozzánk, mert akár kölcsönözni, akár vásárolni szeretnéd ruhádat nálunk megtalálod! Varrást, készítést is vállalunk!" /> 
        </Helmet>
        <Menu />
        <Switch>
          <Route path="/" exact>
              <Main /> 
          </Route>
          <Route path="/hercegnos-ruhak" exact>
              <DisplayData target="hercegnos_ruhak" name="Hercegnős Ruhák" />   
          </Route>
          <Route path="/sello_a_vonalu" exact>
              <DisplayData target="sello_a_vonalu" name="Sellő, A-vonalú Ruhák"/>   
          </Route>
          <Route path="/plus-size-modellek" exact>
              <DisplayData target="plus_size" name="Plus Size Modellek"/>   
          </Route>
          <Route path="/klasszik-es-kismama" exact>
              <DisplayData target="klasszikus_es_kismama" name="Klasszikus Fazonú és Kismama Ruhák"/>   
          </Route>
          <Route path="/kontosok"exact>
              <DisplayData target="kontosok" name="Készülődős köntösök" content="Megvásárolhatóak már 10.000Ft-tól!"/>
          </Route>
          <Route path="/menyecske-ruhak" exact>
              <DisplayData target="menyecske_ruhak" name="Menyecske Ruhák" content="Megvásárolhatók már 55.000Ft-tól!</br>   
Vállalunk készítést, egyedi elképzelés szerint is!"/>
          </Route>
          <Route path="/alkalmi-ruhak" exact>
              <DisplayData target="alkalmi_ruhak" name="Alkalmi Ruhák" content="Megvásárolhatók már 55.000Ft-tól!</br>   
Vállalunk készítést, egyedi elképzelés szerint is!"/>
          </Route>
          <Route path="/szalagavatos-ruhak" exact>
              <DisplayData target="szalagavatos_ruhak" name="Szalagavatós Ruhák"/>   
          </Route>
          <Route path="/kislany-ruhak" exact>
              <DisplayData target="kislany_ruhak" name="Koszorúslány és elsőáldozó ruhák"/>   
          </Route>
          <Route path="/chosen-us" exact>
              <ChosenUs />   
          </Route>
          <Route path="/about"exact>
              <About />   
          </Route>
          <Route path="/contact"exact>
              <Contact />   
          </Route>
           <Route path="/login"exact>
              <Login />
          </Route>
          <Route path="/admin"exact>
              <Clothes authorized={false}/>
          </Route>
          <Route path="/admin/sorting"exact>
              <Sorting />
          </Route>
          <Route path="/admin/adminChosenUs"exact>
              <AdminChosenUs />
          </Route>
          <Route path="/admin/adminLanding"exact>
              <AdminLanding path="landing_page"/>
          </Route>
          <Route path="/admin/adminAbout"exact>
              <AdminLanding path="about_page" />
          </Route>
           <Route component={NotFound} /> 
        </Switch>
        <Footer /> 
        </Router>
      </div>
  );
}
export default App;
