import React, { useState, useEffect } from 'react'
import "./login.css"
import BackgroungImage from "../../assets/4873152.jpg"
import { Redirect } from 'react-router-dom'
import { useHistory } from 'react-router-dom'
import {PATH} from "../constans"

const Login = () => {

   const [message, setMessage] = useState(null)
   const [error, setError] = useState(null)
   const [username, setUsername] = useState('')
   const [password, setPassword] = useState('')

  const history = useHistory()

   useEffect(()=>{
    setTimeout(function() {
      setError(false)
         }, 3000);
       },
   [error])

   const handleForm = (e) => {
    e.preventDefault() 
    fetch( PATH + "user.php", {
        method: "post",
		    body: JSON.stringify({
          username: username,
          password: password
        }),
		    headers: {
          'Content-Type': 'application/json',
        }
    })
    .then(res => res.json())
    .then(result => {
      if(result?.error) {
        setError(result?.error)
        setUsername('')
        setPassword('')
      }
      if(result?.message) {
        sessionStorage.setItem('userData', result?.message)
        history.push('/admin')
      }
    }).catch(err => console.log(err))

   }

  return (
    <div className='login' style={{ backgroundImage: `url(${BackgroungImage})` }}>
      <h1>Login Page</h1>
    <form onSubmit={handleForm} className="form">
      <h3 className='form-head'>Bejelentkezés:</h3>
      <p>
        <label htmlFor="name">Felhasználónév:</label>
        <input type="text" name="username" id="username" value={username} onChange={(e)=> setUsername(e.target.value)} required/>
      </p>
      <p>
        <label htmlFor="password">Jelszó:</label>
        <input type="password" name="password" id="password" value={password} onChange={(e)=> setPassword(e.target.value)} required/>
      </p>
      <button>Elküldés</button>
      <div className='form-message'>{error && error }</div>
    </form>
    </div>
  )
}

export default Login
