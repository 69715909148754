import React, { useEffect, useState } from 'react'
import "./chosen-us.css"
import BackgroungImage from "../../assets/4873152.jpg"
import Modal from "../Modal"
import { useLocation } from 'react-router-dom'
import {PATH} from "../constans"


const ChosenUs = () => {

 const target = "akik_mar_minket"   
// GET DATA FROM Link
   const location = useLocation() 

   // GET DATA FROM DATABASE
   const [data, setData] = useState([])

    useEffect(()=>{
      fetch(PATH + "read.php?category=" + target)
        .then(res => res.json())
        .then(result => {
          setData(result)
      })
  },[])

const [clickedImg, setClickedImg] = useState(null)
const [currentIndex, setCurrentIndex] = useState(null)

  const handleClick = (item, index) => {
    setCurrentIndex(index)
    setClickedImg(item.image)
  }

  const handleRotationLeft = () => {
    const totalLength= data.data.length
    if (currentIndex+1 >= totalLength) {
      setCurrentIndex(0)
      const newUrl = data.data[0].image
      setClickedImg(newUrl)
      return
    }
    const newIndex = currentIndex+1
    const newUrl = data.data.filter((item)=>{
      return data.data.indexOf(item) === newIndex
    })
    const newItem = newUrl[0].image
    setClickedImg(newItem)
    setCurrentIndex(newIndex)
  }

  const handleRotationRight = () => {
    const totalLength= data.data.length
    if (currentIndex === 0) {
      setCurrentIndex(totalLength-1)
      const newUrl = data.data[totalLength-1].image
      setClickedImg(newUrl)
      return
    }
    const newIndex = currentIndex-1
    const newUrl = data.data.filter((item)=>{
      return data.data.indexOf(item) === newIndex
    })

    const newItem = newUrl[0].image
    setClickedImg(newItem)
    setCurrentIndex(newIndex)
  }

  return (
    <div className="chosen-main" style={{ backgroundImage: `url(${BackgroungImage})`}}>
    <div className='container-chosen' >
          {data.data && data.data.length > 0 && data.data.map((item, index) => (
            <div key={index} onClick={()=> handleClick(item, index)}>
              <img src={item.image} alt={item.className} className="container-chosen__image"/>
            </div>  
          ))}
        {clickedImg && <Modal clickedImg={clickedImg} handleRotationRight={handleRotationRight} setClickedImg={setClickedImg} handleRotationLeft={handleRotationLeft}/>}
    </div>
    </div>
  )
}

export default ChosenUs
