import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useHistory } from 'react-router-dom'
import {AiOutlineFacebook} from 'react-icons/ai' 
import {AiOutlineInstagram} from 'react-icons/ai'
import {CgClose} from 'react-icons/cg'
import * as FaIcons from "react-icons/fa"
import "./menu.css"
import logo from '../../assets/tiffany_logo.png'

const Menu = () => {
   const [showMenu, setShowMenu] = useState(false)
   const history = useHistory()
   // history.push('/admin')

  return (
      <nav className='navBar'>
         <div className="logo" >
          <img src={logo} alt="logo" />
          {!showMenu && <FaIcons.FaBars  className='mobile-menu-icon' onClick={()=> setShowMenu(!showMenu)}/>}
          {showMenu && <CgClose className='mobile-menu-icon' onClick={()=> setShowMenu(!showMenu)}/>}
         </div>
         <div className='menu' id={showMenu ? "hidden" : ''}>
            <div className="menu-text-content" onClick={()=> history.push('/contact')}>
               <h3 className='clothes-login' >Ruhapróbára bejelentkezés</h3>
               <h3 className='clothes-login small-text'>06-62/321-811; 06-30/649-0130</h3>
            </div>
            <ul className='clothes'>
               <h4>KOLLEKCIÓNK</h4>
               <li className='main-menu first-level'>Menyasszonyi ruhák<span class="arrow-down"></span>  
                  <ul className='sub-menu'>
                     <Link onClick={()=> setShowMenu(!showMenu)} title="hercegnos-ruhak" to='/hercegnos-ruhak'><li>Hercegnős</li></Link>
                     <Link onClick={()=> setShowMenu(!showMenu)} title="sello_a_vonalu" to='/sello_a_vonalu'><li>Sellő, A-vonalú Ruhák</li></Link>
                     <Link onClick={()=> setShowMenu(!showMenu)} to={{pathname: '/plus-size-modellek', id: 'plus-size' }}><li>Plus Size modellek</li></Link>
                     <Link onClick={()=> setShowMenu(!showMenu)} to={{pathname: '/klasszik-es-kismama', id: 'kismama' }} className="lista"><li>Egyszerű, klasszikus fazonú és kismama ruhák</li></Link>
                  </ul>
               </li>
               <Link onClick={()=> setShowMenu(!showMenu)} to='/kontosok'><li className='first-level'>Készülődős köntösök</li></Link>
               <Link onClick={()=> setShowMenu(!showMenu)} to={{pathname: '/menyecske-ruhak', id: 'menyecske' }}><li className='first-level'>Menyecske ruhák</li></Link>
               <Link onClick={()=> setShowMenu(!showMenu)} to={{pathname: '/alkalmi-ruhak', id: 'alkalmi-menyecske' }}><li className='first-level'>Alkalmi ruhák</li></Link>
               <Link onClick={()=> setShowMenu(!showMenu)} to={{pathname: '/szalagavatos-ruhak', id: 'szalagavatos' }}><li className='first-level'>Szalagavatós ruhák</li></Link>
               
               <Link onClick={()=> setShowMenu(!showMenu)} to={{pathname: '/kislany-ruhak', id: 'kislany' }}><li className='first-level'>Koszorúslány és elsőáldozó ruhák</li></Link>
            </ul>

            <section>
            <hr className='hr'/>
            <Link onClick={()=> setShowMenu(!showMenu)} to={{pathname: '/about', id: 'bemutakozas' }}><h4 className='menu-elemek'>BEMUTATKOZÁS</h4></Link>
            <Link onClick={()=> setShowMenu(!showMenu)} to={{pathname: '/chosen-us'}}><h4 className='menu-elemek'>AKIK MINKET VÁLASZTOTTAK</h4></Link>
            <Link onClick={()=> setShowMenu(!showMenu)} to={{pathname: '/contact'}}><h4 className='menu-elemek'>ELÉRHETŐSÉG ÉS NYITVATARTÁS</h4></Link>
            </section>

            <div className="icons-container">
               <span className="icons"><a href="https://www.facebook.com/people/Tiffany-Esk%C3%BCv%C5%91i-Szalon/100057361603432/" target="_blank" rel="noreferrer"><AiOutlineFacebook /></a></span>
               <span className="icons"><a href="https://instagram.com/tiffany_szalon_szeged" target="_blank" rel="noreferrer"><AiOutlineInstagram /></a></span>
            </div>
       </div>
      </nav>
   
  )
}

export default Menu
