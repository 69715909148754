import React, { useEffect, useState } from 'react';
import { Editor} from 'react-draft-wysiwyg';
import { EditorState, ContentState, convertToRaw, convertFromHTML } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import AdminMenu from './AdminMenu'
import "./adminLanding.css"
import BackgroungImage from "../../assets/4873152.jpg"
import axios from 'axios';
import {PATH} from "../constans"


const AdminLanding = ({path}) => {
   const [content, setContent] = useState("")
   const [editorState, setEditorState] = useState(EditorState.createEmpty())    // editorState: az editor aktuális tartalma, setEditorState: érték beállítása 
   const [loading, setLoading] = useState(true)
   const [error, setError] = useState("")

   useEffect(()=>{
      setContent(draftToHtml(convertToRaw(editorState.getCurrentContent())))
   },[editorState])

   useEffect(()=>{
      getBlog()
   },[path])

   const getBlog = async () => {
        try 
        {
            const response = await axios({
                method: 'GET',
                url: PATH + "readHTML.php?path=" + path,
            });

            if (response.status === (200 || 304))
            {
                    /** Convert html string to draft JS */
                    const contentBlock = htmlToDraft(response.data);
                    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                    const editorStateStore = EditorState.createWithContent(contentState);

                    setEditorState(editorStateStore);
                }
            else
            {
                setError(`Unable to retrieve data`);
            }
        } 
        catch (error) 
        {
            setError(error.message);
        }
        finally
        {
            setLoading(false);
        }
    }


   const saveToDatabase = (event) => {
    
      event.preventDefault();
      // event.persist();
      const token = sessionStorage.getItem("userData")

   //    axios.post(PATH + 'createHTML.php', {
   //       headers: {
   //        "X-Auth-Token" : token
   //      },
   //       content: content,
   //    })
   //    .then(res => {
   //      if(res.data.success === true){
   //       console.log("cool")
   //      }
   //    })
   let formData = new FormData();
   formData.append('content', content);

    fetch(PATH + 'createHTML.php?path=' + path, {
        method: 'POST',
        headers: {
          "X-Auth-Token" : token,
         //  "Content-Type": "application/json",
         //  "Content-type": "text/html",

        },
      //   body: formData, 
        body: JSON.stringify({
            "content": content
         }),
        
    }).then((response)=> {
        return response.json()
    }).then((data)=>{
        console.log(data);
    }).catch(err => { console.log(err) })

   }  // end of arrow function


  return (
    <div className='adminLanding'style={{ backgroundImage: `url(${BackgroungImage})` }}>
      <AdminMenu />
      
      <div className="editor">
        
             <button onClick={saveToDatabase}>Mentés</button>
        
         <Editor
            editorState={editorState}
            onEditorStateChange={setEditorState} 
            wrapperClassName="demo-wrapper"
            editorClassName="demo-editor"
            toolbarClassName='toolbarClassName'
         />
      </div>
    </div>
  )
}

export default AdminLanding
