import React, { useEffect, useState } from 'react'
import "./admin.css"
import BackgroungImage from "../../assets/4873152.jpg"
import {PATH} from "../constans"
import AdminMenu from './AdminMenu'

const Admin = ({authorized}) => {
   
  const [data, setData] = useState({
    title: "",
    description: "",
    image: "",
    category: ""
  })

  useEffect(()=>{
    setTimeout(function() {
      setMessage(false)
         }, 3000);
       },
   [data])

  const [error, setError] = useState(null)

  const types= ['image/jpeg','image/png']

  const [message, setMessage] = useState(null)


  // --------------- ADD NEW IMAGE -------------------------------
  // -------------------------------------------------------------
  const handleForm = (e) => {
    e.preventDefault()

    const formData = new FormData(e.target)
    const token = sessionStorage.getItem("userData")
    
    fetch(PATH + 'create.php', {
        method: 'POST',
        headers: {
          "X-Auth-Token" : token
        },
        body: formData
    }).then((response)=> {
        return response.json()
    }).then((data)=>{
        setMessage(data.message)
    }).catch(err => { console.log(err) })
    setData({description: "", title: "", image: "", category: ""})
  }


  // --------------  GET DATA FROM DATABASE ----------------------
  // -------------------------------------------------------------
  const [dbData, setDbData] = useState([])

  let category = ''

  const getData = (e) => {
    e.preventDefault()
    category = e.target.dataset.category
      fetch(PATH + "read.php?category=" + category)
        .then(res => res.json())
        .then(result => {
          setDbData(result)
      })
  }


  // --------------  DELETE DATA FROM DATABASE -------------------
  // -------------------------------------------------------------
  const deleteData = (e) => {
      e.preventDefault()
      let item_id = {
        id:  e.target.dataset.id,
        category: e.target.dataset.category
      }
      // category = e.target.dataset.category
      
     if (window.confirm("Kép törlése?")) {

      fetch(PATH + 'update.php', {
         method: 'POST',
         headers: {"Content-Type": "application/json"},
         body: JSON.stringify(item_id)
      }).then(()=> {
        fetch( PATH + "read.php?category=" + item_id.category)
        .then(res => res.json())
        .then(result => {
          setDbData(result)
         })
        
      }) 
      
      }
    }

  return (

    <div className="admin" style={{ backgroundImage: `url(${BackgroungImage})` }}>
      <AdminMenu />
      
    <form onSubmit={handleForm} className="form">
      <h3 className='form-head'>Új kép hozzáadása:</h3>
      <p>
        <label htmlFor="title">Cím:</label>
        <input type="text" name="title" id="" value={data.title} onChange={(e)=>setData({...data, [e.target.name]: e.target.value})} required/>
      </p>
      <p>
        <label htmlFor="description">Leírás:</label>
        <input type="text" name="description" id="" value={data.description} onChange={(e)=>setData({...data, [e.target.name]: e.target.value})} />
      </p>
      <p>
        <label htmlFor="image">Kép feltöltés:</label><span className='error'>{error && error}</span>
        <input type="file" name="image" id="" onChange={(e)=>
          {
          setError('')
          types.includes(e.target.files[0].type) ? 
          setData({...data, [e.target.name]: e.target.files[0].name}) : 
          setError(' Nem megfelelő képformátum!')
          }
          } 
          required/>
      </p>
      <p>
        <label htmlFor="category">Kategória: </label>
        <select id="" className='admin-select' name="category" onChange={(e)=>setData({...data, [e.target.name]: e.target.value})}>
          {/* <option value="sello_felsello">sello_felsello</option> */}
          {/* <option value="akcios">akcios</option> */}
          {/* <option value="a_vonalu">a_vonalu</option> */}
          <option value="hercegnos_ruhak">hercegnos_ruhak</option>
          <option value="sello_a_vonalu">sello_a_vonalu</option>
          <option value="plus_size">plus_size</option>
          <option value="klasszikus_es_kismama">klasszikus_es_kismama</option>
          <option value="szalagavatos_ruhak">szalagavatos_ruhak</option>
          <option value="kislany_ruhak">Koszorúslány</option>
          <option value="alkalmi_ruhak">alkalmi_ruhak</option>
          <option value="menyecske_ruhak">menyecske_ruhak</option>
          <option value="kontosok">kontosok</option>
          <option value="akik_mar_minket">akik_mar_minket</option>
        </select>
      </p>
      <button className='admin-button'>Feltöltés</button>
      <div className='form-message'>{message && message }</div>
    </form>
    <section className='delete-data'>
      <div className="admin-menu">
        <div className="hercegnos_ruhak" data-category="hercegnos_ruhak" onClick={getData}>hercegnos_ruhak</div>
        <div className="a_vonalu" data-category="sello_a_vonalu" onClick={getData}>sello_a_vonalu</div>
        <div className="plus_size" data-category="plus_size" onClick={getData}>plus_size</div>
        <div className="klasszikus_es_kismama" data-category="klasszikus_es_kismama" onClick={getData}>klasszikus_es_kismama</div>
        <div className="szalagavatos_ruhak" data-category="szalagavatos_ruhak" onClick={getData}>szalagavatos_ruhak</div>
        <div className="kislany_ruhak" data-category="kislany_ruhak" onClick={getData}>Koszorúslány</div>
        <div className="alkalmi_ruhak" data-category="alkalmi_ruhak" onClick={getData}>alkalmi_ruhak</div>
        <div className="menyecske_ruhak" data-category="menyecske_ruhak" onClick={getData}>menyecske_ruhak</div>
        <div className="kontosok" data-category="kontosok" onClick={getData}>Köntösök</div>
      </div>
      <>
      {dbData.data && dbData.data.length > 0 && <h1>{dbData.data[0].category}</h1> }
      <div className="database-data">
          {dbData.data && dbData.data.length > 0 && dbData.data.map((item ,index)=>(
            <div className= "admin-card" key={index} >
               <img className = "database-data" src={item.image} alt={index} onClick={deleteData} data-id={item.id} data-category={item.category}/>
               <p>id: {item.id}, név: {item.title}</p>
            </div>
          )) }
      </div>  
      </>
    </section>
    </div>
  )
}

export default Admin
