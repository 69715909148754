import {
  SortableContext,
  horizontalListSortingStrategy
} from "@dnd-kit/sortable";

import { Task } from "../Task/Task";

import "./Column.css";

export const Column = ({ tasks }) => {
  return (
    <div className="column">
      <SortableContext items={tasks} strategy={horizontalListSortingStrategy}>
        {tasks.map((task,index) => (
          <Task key={task.id} id={task.id} title={task.title} image={task.image} index={index} sorted={task.sorted} />
        ))}
      </SortableContext>
    </div>
  );
};
