import React, { useEffect, useState } from 'react'
import BackgroungImage from "../../assets/4873152.jpg"
import AdminMenu from './AdminMenu'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import {PATH} from "../constans"
import "./AdminChosenUs.css"
import noImage from "../../assets/istockphoto-1399859917-170667a.jpg"  


const AdminChosenUs = () => {
  const [data, setData] = useState({
    title: "",
    image: "",
  })

  const [file, setFile] = useState("")

  const history = useHistory()

  useEffect(()=>{
    setTimeout(function() {
      setMessage(false)
         }, 4000);
       },
   [data])

  const [error, setError] = useState(null)

  const types= ['image/jpeg','image/png']

  const [message, setMessage] = useState(null)


// --------------- ADD NEW IMAGE -------------------------------
  // -------------------------------------------------------------
  const handleForm = (e) => {
    e.preventDefault()

    const formData = new FormData(e.target)
    const token = sessionStorage.getItem("userData")
    // console.log([...formData]);
    fetch(PATH + 'create.php', {
        method: 'POST',
        headers: {
          "X-Auth-Token" : token
        },
        body: formData
    }).then((response)=> {
        return response.json()
    }).then((data)=>{
        setData({title: "", image: ""})
        setFile("")
        document.getElementById("image-container").value = ""
        setMessage(data.message)
    }).catch(err => { console.log(err) })
  }


  // --------------  GET DATA FROM DATABASE ----------------------
  // -------------------------------------------------------------
  const [dbData, setDbData] = useState([])

  let category = ''

  const getData = (e) => {
    e.preventDefault()
    category = e.target.dataset.category
      fetch(PATH + "read.php?category=" + category)
        .then(res => res.json())
        .then(result => {
          setDbData(result)
      })
  }


  // --------------  DELETE DATA FROM DATABASE -------------------
  // -------------------------------------------------------------
  const deleteData = (e) => {
      e.preventDefault()
      let item_id = {
        id:  e.target.dataset.id,
        category: e.target.dataset.category
      }
      
     if (window.confirm("Kép törlése?")) {

      fetch(PATH + 'update.php', {
         method: 'POST',
         headers: {"Content-Type": "application/json"},
         body: JSON.stringify(item_id)
      }).then(()=> {
        fetch( PATH + "read.php?category=" + item_id.category)
        .then(res => res.json())
        .then(result => {
          setDbData(result)
         })
        
      }) 
      
      }
    }

  //  JSX ELEMENTS: 
  return (
    <div className="adminChosenUs" style={{ backgroundImage: `url(${BackgroungImage})` }}>
      <AdminMenu />
      <div className="choseUsContent">
        <div className="formContent">
         <form onSubmit={handleForm} className="form" id="form">
      <h3 className='form-head'>Új kép hozzáadása:</h3>
      <p>
        <label htmlFor="title">Cím:</label>
        <input type="text" name="title" id="" value={data.title} onChange={(e)=>setData({...data, [e.target.name]: e.target.value})} required/>
      </p>
      <p>
        <label htmlFor="image">Kép feltöltés:</label><span className='error'>{error && error}</span>
        <input type="file" name="image" id="image-container" onChange={(e)=>
          {
          setError('')
          types.includes(e.target.files[0].type) ? 
          setData({...data, [e.target.name]: e.target.files[0].name}) : 
          setError(' Nem megfelelő képformátum!')
          setFile(e.target.files[0]) 
          }
          } 
          required/>
      </p>
      <button className='admin-button'>Feltöltés</button>
      <div className='form-message'>{message && message }</div>
    </form>
        </div>
        <div className="showImage">
           <img src={file ? URL.createObjectURL(file) : noImage} alt="" />
        </div>
      </div>
      
      <div className="admin-chosenus-menu">
        <div className="akik_mar_minket" data-category="akik_mar_minket" onClick={getData} >Képek megjelenítése</div>
      </div>
      <>
      {dbData.data && dbData.data.length > 0 && <h1>{dbData.data[0].category}</h1> }
      <div className="database-data">
          {dbData.data && dbData.data.length > 0 && dbData.data.map((item ,index)=>(
            <div className= "admin-card" key={index} >
               <img className = "database-data" src={item.image} alt={index} onClick={deleteData} data-id={item.id} data-category={item.category}/>
               <p>id: {item.id}, név: {item.title}</p>
            </div>
          )) }
      </div>  
      </>
    </div>
  )
}

export default AdminChosenUs
