import React from 'react'
import BackgroungImage from "../../assets/4873152.jpg"
import "./notfound.css"

const NotFound = () => {
  return (
    <div className='notfound' style={{ backgroundImage: `url(${BackgroungImage})` }}>
      <h1>Error 404 – A keresett oldal nem található!</h1>
    </div>
  )
}

export default NotFound
