import React, { useEffect, useState } from 'react'
import BackgroungImage from "../../assets/4873152.jpg"
import Landing_pic from "../../assets/landing.jpg"
import "./main.css";
import {PATH} from "../constans"
import DOMPurify from 'dompurify';

const Main = () => {
  
    const [data, setData] = useState()

    useEffect(()=>{
      fetch(PATH + "readHTML.php?path=landing_page")
        .then(res => res.json())
        .then(result => {
          setData(result)
        })
    },[data])
    
  return (
    <div className='main' style={{ backgroundImage: `url(${BackgroungImage})` }}>
      <div className="main-landing">
        <img src={Landing_pic} alt="nyito" />
      </div>
      <h3 className='main-clothes-login'>Ruhapróbára bejelentkezés</h3>  
      <h3 className='main-clothes-login small-text'>06-62/321-811; 06-30/649-0130</h3>  
      <h1 className='main-head'>Esküvőd lesz  és még nem találtad meg álmaid ruháját?</h1> 
      <div className='container-main'>
        <p className='first'>Ahány menyasszony, annyi fajta elképzelés.</p>   
        <div className="landingContent" id="landingContent"
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data) }} />
          <p  className='seventh' > Szalonunkba belépve valóra válthatod álmaidat!
            Sok szeretettel várunk!
          </p>
     </div>   
    </div>
  )
}

export default Main
