import React from 'react'
import "./footer.css"

const Footer = () => {
  return (
    <div className='footer'>
      <p>Tiffany Szalon, Copyright &copy; 2022</p> 
    </div>
  )
}

export default Footer
