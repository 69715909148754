import React, { useEffect, useState } from 'react'
import BackgroungImage from "../../assets/4873152.jpg"
import "./about.css"
import Pictures1 from "../../assets/tom-the-photographer-8yPA6ZYq0-s-unsplash.jpg"
import {PATH} from "../constans"
import DOMPurify from 'dompurify';

const About = () => {
   const [data, setData] = useState()

    useEffect(()=>{
      fetch(PATH + "readHTML.php?path=about_page")
        .then(res => res.json())
        .then(result => {
          setData(result)
        })
    },[data])

  return (
     <div style={{ backgroundImage: `url(${BackgroungImage})` }}>
     <h1 className='about-head'>Esküvőd lesz  és még nem találtad meg álmaid ruháját?</h1> 
     <div className='container-about' >
    <section >
     <p className='first'>Ahány menyasszony, annyi fajta elképzelés.</p> 
    
     <div className="aboutContent" id="aboutContent" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data) }} />

     <p  className='seventh' > Szalonunkba belépve valóra válthatod álmaidat!<br/>
      Sok szeretettel várunk!
     </p>
</section>
   <aside>
      <img src={Pictures1} alt="kep1" />
   </aside>
   </div>
   </div>
  )
}

export default About
