import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import BackgroungImage from "../../assets/4873152.jpg"
import "./displaydata.css"
import Modal from "../Modal"
import {PATH} from "../constans"
import { Helmet } from 'react-helmet';
import DOMPurify from 'dompurify'


const DisplayData = ({target, name, content=""}) => {
  

  // GET DATA FROM Link
   const location = useLocation() 

   // GET DATA FROM DATABASE
   const [data, setData] = useState([])

    useEffect(()=>{
      fetch(PATH + "read.php?category=" + target)
        .then(res => res.json())
        .then(result => {
          setData(result)
      })
  },[name])

  const [clickedImg, setClickedImg] = useState(null)
  const [currentIndex, setCurrentIndex] = useState(null)

  const handleClick = (item, index) => {
    setCurrentIndex(index)
    setClickedImg(item.image)
  }

  const handleRotationLeft = () => {
    const totalLength= data.data.length
    if (currentIndex+1 >= totalLength) {
      setCurrentIndex(0)
      const newUrl = data.data[0].image
      setClickedImg(newUrl)
      return
    }
    const newIndex = currentIndex+1
    const newUrl = data.data.filter((item)=>{
      return data.data.indexOf(item) === newIndex
    })
    const newItem = newUrl[0].image
    setClickedImg(newItem)
    setCurrentIndex(newIndex)
  }

  const handleRotationRight = () => {
    const totalLength= data.data.length
    if (currentIndex === 0) {
      setCurrentIndex(totalLength-1)
      const newUrl = data.data[totalLength-1].image
      setClickedImg(newUrl)
      return
    }
    const newIndex = currentIndex-1
    const newUrl = data.data.filter((item)=>{
      return data.data.indexOf(item) === newIndex
    })

    const newItem = newUrl[0].image
    setClickedImg(newItem)
    setCurrentIndex(newIndex)
  }

  return (
    <section className='container' style={{ backgroundImage: `url(${BackgroungImage})` }}>
      <Helmet>
            <title>Tiffany - {name}</title> 
            <meta name="description" content="Gyere el hozzánk, mert akár kölcsönözni, akár vásárolni szeretnéd ruhádat nálunk megtalálod! Varrást, készítést is vállalunk!" />
            <meta name="keywords" content="TIFFANY SZALON, ESKÜVŐI RUHÁK SZEGED, MENYASSZONYI RUHÁK, KÉSZÜLŐDŐS KÖNTÖSÖK, ÁTÖLTÖZŐS KÖNTÖSÖK, MENYASSZONYI KÖNTÖSÖK, SZATÉN ESKÖVŐI KÖNTÖSÖK, KÉSZÜLŐDŐS KÖNTÖSÖK, MENYECSKE RUHÁK, KISLÁNY RUHÁK, SZALAGAVATÓS RUHÁK,KOSZORÚSLÁNY RUHA, KISLÁNY RUHA, ELSŐÁLDOZÓ RUHA, MENYECSKE RUHA, VARRÁS, RUHAKÉSZÍTÉS, EGYEDI TERVEZÉS, SZABÁS, ÁTALAKÍTÁS, BÁLI RUHA, BÁLI TÁNC RUHA, KISMAMA RUHA, Nagy méretű esküvői ruha, Nagy méretű menyasszonyi ruha, Plus Size esküvői ruha, Molett esküvői ruha, 6XL-ig esküvői ruha, esküvői ruha 68-as méretig, Extra méretű esküvői ruha, Telt méretben vagy-és Telt méretű esküvői ruha" />
            <meta property="og:title" content="Tiffany - Esküvői és menyasszonyi ruhaszalon" />
            <meta property="og:type" content="product" />
            <meta property="og:url" content="https://tiffanyszalonszeged.hu/" />
            <meta property="og:description" content="Gyere el hozzánk, mert akár kölcsönözni, akár vásárolni szeretnéd ruhádat nálunk megtalálod! Varrást, készítést is vállalunk!" />  
        </Helmet>
      <h1 className='head'>{name}</h1>  
      <h3 className='content' dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(content)}}></h3>  
      {!data.data && <h1 className='nodata'>Hamarosan...</h1>}
      <div className="cards">
          {data.data && data.data.length > 0 && data.data.map((item, index) => (
            <div key={index} style={{backgroundImage:`url(${item.image})`, backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat:'no-repeat'  }}  className="card" onClick={()=> handleClick(item, index)} >
            </div>
          ))}
          {clickedImg && <Modal clickedImg={clickedImg} handleRotationRight={handleRotationRight} setClickedImg={setClickedImg} handleRotationLeft={handleRotationLeft}/>}
      </div>
     </section>  
  )
}

export default DisplayData


